/* UnauthorizedPage.css */
.unauthorized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f8f8;
    color: #333;
    font-family: 'Arial', sans-serif;
}
