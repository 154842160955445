.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background: #f0f0f5;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.page-not-found h1 {
    font-size: 96px;
}
.page-not-found p {
    font-size: 24px;
    color: #666;
  }

.home-link {
    margin-top: 20px;
    padding: 10px 20px;
    background: #0077cc;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
}